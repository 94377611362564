import * as React from "react"
import { HeadFC, useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import {SEO} from "../components/seo"
import Intro from "../components/intro"
import OmNiels from "../components/omNiels"
import Timeline from "../components/timeline"
import Boeger from "../components/boeger"
import Book from "../components/book"
import Kontakt from "../components/kontakt"

const intro = {
  heading: "Om Niels Jensen",
  description: "<h2>Københavnsk journalist, forfatter og foredragsholder.</h2>"
}


const Om = () => {
  
  return (
      <Layout>
        <Intro 
          heading={intro?.heading} 
          description={intro?.description} 
          sm 
        />
        <OmNiels/>
        <Timeline/>
        <Boeger/>
        <Book/>
        <Kontakt/>
    </Layout>
  )
}

export default Om


export const Head: HeadFC = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )
  return (
    <SEO title={`Om | ${site.siteMetadata.title}`} description={site.siteMetadata.description}/>
  )
}
