import React from "react"
import Container from "../container"
import "./timeline.scss"

const currentYear = new Date().getFullYear()

const data = [
  {
    date: `2022 - ${currentYear}`,
    text: "Foredragsholder og formidler af hemmelige russiske kort over Danmark til myndigheder, samlere og museer."},
  {
    date: `1980 - ${currentYear}`,
    text: "Forfatter til mere end 40 bøger om historiske emner"},
  {
    date: "1974-2007",
    text: "Civilt: Dagbladsjournalist i 35 år."},
  {
    date: "1970'erne og 1980'erne",
    text: "Uddannet taktisk efterretningsofficer ved Hærens Specialskole. Funktion som Taktisk efterretningsofficer og kontra-efterretningsofficer."},
  {
    date: "1968-1970",
    text: "Flyvevåbnet: Flyverløjtnant af 2. grad af Reserven/sekondløjtnant."},
  {
    date: "1968",
    text: "Teknikumingeniør"
  },
  {
    date: "1942",
    text: "Født"
  }
]

const Timeline = () => {
  return (
    <div className="timeline">
      <Container>
        <div className="timeline__inner">
          {data.map((item:any, index:number) => (
            <div className={`timeline__container timeline__container${index % 2 == 0 ? "--left" : "--right"}`} key={index}>
              <div className="timeline__content">
                {item.date && <h4>{item.date}</h4>}
                {item.text && <p>{item.text}</p>}
              </div>
            </div>
          ))}
        </div>

      </Container>
    </div>
  )
}
export default Timeline