import React from "react"
import "./omNiels.scss"
import { StaticImage } from "gatsby-plugin-image"

import Container from "../container"

interface OmNielsProps {
  invert?: boolean;
}
const omNiels = ({invert}: OmNielsProps) => {
  return (
    <div className={`om${invert ? ' om--inverted' : ''}`}>
      <Container>
        <div className="om__inner">
          <div className="om__image">
            <StaticImage src="../../images/niels_jensen.jpg" alt="Niels Jensen - Forfatter og foredragsholder"/>
          </div>
          <div className="om__text">
            <h2>Forfatter og foredragsholder</h2>
            <p>
              Niels Jensen har en fortid som efterretningsofficer i det danske forsvar under Den kolde Krig, og har i civilt omfang arbejdet som dagbladsjournalist og forfatter i mere end 40 år.
            </p>
            <p>
              Han er uddannet reserveofficer i Flyvevåbnet(Flyverløjtnant af 2. Grad af Reserven) og ved Hærens Specialskole.
            </p>
            <p>
              Hans baggrund som taktisk efterretningsofficer i 1970'erne og 1980'erne har ledt til en specialicering i den hemmelige russiske kortlægning af Danmark under Den kolde Krig og tiden, der fulgte.
            </p>
            <p>Han har skrevet mere end 40 bøger non-fiction og er nu foredragsholder om Danmarks nyere historie - især Den kolde Krig.</p>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default omNiels;
